<template>
  <div class="d-flex justify-space-between flex-column flex-sm-row flex-md-column pr-md-10">
    <v-card
      v-for="(item, index) in items"
      :key="index"
      class="defaultBg py-8 px-10 mb-10 ml-md-0"
      :class="{'ml-sm-5': !!index}"
      flat
      width="100%"
    >
      <div class="title defaultColor--text pb-4 text-no-wrap">{{ item.name }}</div>
      <div class="title primaryColor--text">{{ currency(item.sum) }}</div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import currency from '@/mixins/currency'

export default {
  name: 'TotalInfo',
  inheritAttrs: false,
  mixins: [ currency ],
  data: () => ({
    items: [
      { name: 'Today Income', id: 'today_income', sum: 0 },
      { name: 'Total Income', id: 'total_income', sum: 0 },
      { name: 'Total Pending', id: 'total_pending', sum: 0 },
    ]
  }),
  computed: {
    ...mapState({
      totalsData: state => state.dashboard.totalsData,
    })
  },
  methods: {
    parseData() {
      this.items.forEach(item => {
        item.sum = this.totalsData[item.id]
      })
    }
  },
  watch: {
    totalsData: {
      handler(value) {
        if (!!value) {
          this.parseData()
        }
      }
    }
  }
}
</script>
