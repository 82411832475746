var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title text-uppercase secondaryColor--text pt-14 pb-4"},[_vm._v("Pending invoices")]),_c('v-data-table',{staticClass:"defaultBg",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"loader-height":"2","mobile-breakpoint":"0","hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.buyer",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{staticClass:"rounded",attrs:{"color":"mainBg","open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-no-wrap"},on),[_vm._v(_vm._s(item.buyer.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.buyer.email))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.amount))+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.getFormatDate(item['created_at'])))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }