<template>
  <v-card class="defaultBg py-10 px-5" flat>
    <div id="dashboardChart"></div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import noData from 'highcharts/modules/no-data-to-display'
noData(Highcharts)

export default {
  name: 'DashboardChart',
  inheritAttrs: false,
  data: () => ({
    myChart: null,
  }),
  computed: {
    ...mapState({
      isLoading: state => state.dashboard.loading,
      chartData: state => state.dashboard.chartData,
    }),
    startDate() {
      if (this.chartData.length) {
        const start = new Date(this.chartData[0].date)
        return Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0)
      }
      return ''
    }
  },
  methods: {
    renderChart() {
      this.myChart = Highcharts.chart('dashboardChart', {
        chart: {
          type: 'column',
          zoomType: 'x',
          spacingRight: 20
        },
        colors: ['#7798BF', '#0e776f'],
        title: { text: 'Closed invoices and amount' },
        loading: {
          hideDuration: 100,
          showDuration: 100,
          style: {
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.65)',
            opacity: 0.5,
            textAlign: 'center'
          }
        },
        legend: {
          enabled: false
        },
        xAxis: [{
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%H:%M %P',
            day: '%e. %b',
            week: '%e. %b',
            month: '%b \'%y',
            year: '%Y'
          }
        }],
        yAxis: [{
          title: {
            text: 'Income'
          },
        }, {
          title: {
            text: 'Count'
          },
          opposite: true
        }],
        plotOptions: {
          series: {
            pointStart: (new Date()).getTime() * 1000,
            events: {
              legendItemClick: () => false
            }
          },
          column: {
            pointInterval: 24 * 3600 * 1000,
            pointStart: this.startDate,
            borderWidth: 0
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          borderColor: '#F4F0F9',
          headerFormat: '<span class="body-2 pb-4">{point.key}</span><br />',
          padding: 12,
          style: {
            fontSize: '16px'
          }
        },
        series: [{
          name: 'Income',
          data: this.chartData.map(item => item.income),
          tooltip: {
            valuePrefix: '$',
          },
        }, {
          name: 'Count',
          data: this.chartData.map(item => item.count),
          yAxis: 1
        }],
        lang: { noData: 'There is no data for the selected date' },
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: this.$vuetify.theme.dark ? '#f9f9fa' : 'rgba(0,0,0,0.69)'
          }
        }
      })
    },
  },
  watch: {
    isLoading: {
      handler(value) {
        if (value) {
          if (!this.myChart) {
            this.renderChart()
          }
          this.myChart.hideNoData()
          this.myChart.showLoading()
        } else {
          if (!!this.myChart) {
            this.myChart.hideLoading()
          }
          this.renderChart()
        }
      }
    }
  }
}
</script>
