<template>
  <div class="py-10 py-md-20 px-9 px-md-18">
    <v-row no-gutters>
      <v-col cols="12" md="2">
        <TotalInfo />
      </v-col>
      
      <v-col cols="12" md="10">
        <DashboardChart />
      </v-col>
    </v-row>
    
    <PayedInvoices />
    
    <NewInvoices />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import TotalInfo from '@/components/dashboard/TotalInfo'
import DashboardChart from '@/components/dashboard/DashboardChart'
import PayedInvoices from '@/components/dashboard/PayedInvoices'
import NewInvoices from '@/components/dashboard/NewInvoices'

export default {
  name: 'Dashboard',
  components: { TotalInfo, DashboardChart, PayedInvoices, NewInvoices },
  data: () => ({}),
  computed: {},
  mounted() {
    this.getDashboardData()
  },
  methods: {
    ...mapActions({
      getDashboardData: 'dashboard/GET_DASHBOARD_DATA'
    }),
    ...mapMutations({
      resetState: 'dashboard/SET_RESET_STATE'
    })
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
